import React from "react";
import HomeDesk from "./Desk/index";
import HomeMob from "./Mobile/index";

const Home = () => {
  return (
    <>
      <HomeMob />
      <HomeDesk />
    </>
  );
};

export default Home;
