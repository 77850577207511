import React from "react";
import HeaderMob from "./Mobile/index";
import HeaderDesk from "./Desk/index";

const Header = () => {
  return (
    <>
      <HeaderMob />
      <HeaderDesk />
    </>
  );
};
export default Header;
