import React from "react";
import FooterMob from "./Mobile/index";
import FooterDesk from "./Desk/index";



const Footer = () => {
  return (
    <>
      <FooterMob />
      <FooterDesk />
    </>
  );
};

export default Footer;
